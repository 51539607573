<template>
    <div>
        <!-- <myBreadcrumb :nav="nav"></myBreadcrumb> -->
        <div class="filter">
            <div class="item">
                <div class="title">场馆类型：</div>
                <el-select v-model="charge1" placeholder="请选择" @change="getList">
                    <el-option v-for="item in charge1List" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="title">站点类型：</div>
                <el-select v-model="charge" placeholder="请选择" @change="getList">
                    <el-option v-for="item in chargeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ path: '/inCharge/detail', query: { id: item.id } })">
                <img class="active" :src="item.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.libName }}</div>
                    <div class="address">
                        <img src="@/assets/images/Frame-43.png" alt="" />
                        <span>使用面积：{{ item.usableArea || 0 }}平</span>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getList" @current-change="getList" :current-page.sync="page" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
import { getLibrarys } from '@/api';
export default {
    name: 'Collection',
    data() {
        return {
            limit: 12,
            page: 1,
            total: 0,
            charge: null,
            chargeList: [],
            charge1: '文化馆',
            charge1List: [],
            list: [],
            nav: [
                {
                    title: '首页',
                    path: '/',
                },
                {
                    title: '总分管',
                },
            ],
        };
    },
    created() {
        this.getType();
    },
    methods: {
        getData() {
            // console.log(this.limit, this.page);
        },
        getType() {
            let data = {
                // latitude: localStorage.getItem('latitude') || null,
                // longitude: localStorage.getItem('longitude') || null,
            };
            getLibrarys(data).then((res) => {
                this.chargeList.push({
                    label: '全部',
                    value: null,
                });
                res.data.libLevelList.forEach((item, index) => {
                    this.chargeList.push({
                        label: item,
                        value: item,
                    });
                });
                // this.charge1List.push({
                //     label: '全部',
                //     value: null,
                // });
                res.data.libTypeList.forEach((item, index) => {
                    this.charge1List.push({
                        label: item,
                        value: item,
                    });
                });
                // this.list = res.data.libraryList;
                this.getList();
            });
        },
        getList() {
            let data = {
                id: localStorage.getItem('id'),
                libLevel: this.charge,
                libType: this.charge1,
                pageNum: this.page,
                pageSize: this.limit,
                // latitude: localStorage.getItem('latitude'),
                // longitude: localStorage.getItem('longitude'),
            };
            getLibrarys(data).then((res) => {
                this.list = res.data.libraryList;
                console.log(this.list)
                this.total = res.data.total;
            });
        },
    },
};
</script>

<style scoped lang="less">
::v-deep .is-active {
    background: white !important;
}
::v-deep .el-date-editor--daterange.el-input__inner {
    width: max-content;
}

.filter {
    display: flex;
    align-items: center;
    .item {
        display: flex;
        align-items: center;

        .title {
            display: flex;
            width: max-content;
        }

        .el-select {
            width: 160px;
            margin-right: 24px;

            .el-input__inner {
                border: 1px solid #e4e7ed;
                box-sizing: border-box;
                border-radius: 4px;
                font-size: 14px;
                line-height: 17px;
                color: #606266;
            }
        }
    }
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        height: 240px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        margin-top: 24px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        overflow: hidden;

        .active {
            width: 100%;
            height: 240px;
        }

        .info {
            position: absolute;
            bottom: 8px;
            left: 12px;
            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                height: 23px;
                color: white;
                width: 100%;
                .ellipsisLine(1);
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 4px;
                color: white;
                span {
                    font-size: 13px;
                    line-height: 19px;
                }
                img {
                    width: 16px;
                    height: 16px;
                    margin-right: 2px;
                }
            }
        }
    }
}
</style>
